import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';

export default class HeaderToggle {
  inited = false;

  constructor($toggle) {
    this.$toggle = $toggle;

    // Get menu
    const menuId = $toggle.getAttribute('aria-controls');
    this.$menu = document.getElementById(menuId);

    // Menu is missing, stop working
    if (!this.$menu) {
      throw new ReferenceError('Menu target for header toggle is missing in DOM.');
    }

    // Get navigation, menu inner
    this.$navigation = this.$toggle.closest('.header__main-navigation');
    this.$menuInner = this.$menu.querySelector('.header__menu-inner');
    this.$navigationWrap = this.$menu.querySelector('.header__navigation');

    // Bind event listeners
    this.onClickBinded = this.onClick.bind(this);
    this.onNavigationClickBinded = this.onNavigationClick.bind(this);
    this.onMousedownBinded = this.onMousedown.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
  }

  init() {
    // Set state
    this.inited = true;

    // Init focus trap
    this.focusTrap = focusTrap(this.$navigation, {
      escapeDeactivates: false,
      returnFocusOnDeactivate: false,
    });

    // Add event listener
    this.$toggle.addEventListener('mousedown', this.onMousedownBinded);
    this.$toggle.addEventListener('click', this.onClickBinded);
    this.$navigationWrap.addEventListener('click', this.onNavigationClickBinded);
  }

  deinit() {
    // Don't run deinit, if header toggle was never inited
    if (!this.inited) {
      return;
    }

    // Kill focus trap
    this.focusTrap.deactivate();
    this.focusTrap = null;

    // Rename body scrolling
    enableBodyScroll(this.$menuInner);

    // Remove event listener
    this.$toggle.removeEventListener('mousedown', this.onMousedownBinded);
    this.$toggle.removeEventListener('click', this.onClickBinded);
    this.$navigationWrap.removeEventListener('click', this.onNavigationClickBinded);

    // Reset toggle button
    this.$toggle.setAttribute('aria-expanded', 'false');

    // Reset menu
    this.$menu.removeAttribute('tabindex');
    this.$menu.classList.remove('header__menu--open');
    this.$menuInner.scrollTop = 0;

    // Set state
    this.inited = false;
  }

  toggle() {
    // Toggle menu state
    const isOpen = this.$menu.classList.toggle('header__menu--open');

    // Set aria state
    this.$toggle.setAttribute('aria-expanded', isOpen ? 'true' : 'false');

    // Control body scrolling and focus trap
    if (isOpen) {
      // Disable body scrolling
      // I don't why, but only a double call will always disable scrolling on iOS
      disableBodyScroll(this.$menuInner);
      disableBodyScroll(this.$menuInner);

      // Add tabindex 0 to menu
      this.$menu.setAttribute('tabindex', 0);

      // Enable focus trap
      this.focusTrap.activate();

      // Set focus on menu
      this.$menu.focus();

      // Add event listeners
      this.$navigation.addEventListener('keydown', this.onKeydownBinded);

      // Move all alerts inside (focus trap)
      document.querySelectorAll('.alert').forEach($alert => this.$navigation.appendChild($alert));
    } else {
      // Enable body scroll
      enableBodyScroll(this.$menuInner);

      // Remove tabindex 0 from menu
      this.$menu.removeAttribute('tabindex');

      // Disable focus trap
      this.focusTrap.deactivate();

      // Set focus back to toggle button
      unvisibleFocus(this.$toggle);

      // Remove event listeners
      this.$navigation.removeEventListener('keydown', this.onKeydownBinded);

      // Move all alerts back (focus trap)
      document.querySelectorAll('.alert').forEach($alert => document.body.appendChild($alert));
    }
  }

  onMousedown(event) {
    event.preventDefault();
  }

  onClick(event) {
    event.preventDefault();
    this.toggle();
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.toggle();
    }
  }

  onNavigationClick() {
    // Scroll up on every navigation click
    this.$menuInner.scrollTop = 0;
  }
}
