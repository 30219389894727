import { MEDIA_QUERIES } from 'javascripts/constants';
import NavigationMobile from './navigation.mobile';
import NavigationDesktop from './navigation.desktop';

document.querySelectorAll('.navigation').forEach(($navigation) => {
  const navigationMobile = new NavigationMobile($navigation);
  const navigationDesktop = new NavigationDesktop($navigation);

  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.l);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      navigationMobile.deinit();
      navigationDesktop.init();
    } else {
      navigationDesktop.deinit();
      navigationMobile.init();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
