import h from 'hyperscript';
import { t } from 'javascripts/utils/withTranslation';
import icon from '../../_particles/icon/Icon';

document.querySelectorAll('.js-chatbot').forEach(($chatbot) => {
  const iframeUrl = $chatbot.dataset.url;
  const openClass = 'chatbot--open';

  if (iframeUrl) {
    // Iframe
    const $iframe = h('iframe.chatbot__iframe', {
      src: iframeUrl,
      scrolling: 'no',
    });

    // Open button
    const $openButton = h('button.button.button--secondary.chatbot__button', {
      type: 'button',
    },
    icon({ icon: 'bubble', className: 'button__icon' }),
    h('span.button__text', t('Ihre Frage an uns')));

    $chatbot.appendChild($openButton);

    // Close button
    const $closeButton = h('button.button.button--secondary.button--small.chatbot__button', {
      type: 'button',
    },
    icon({ icon: 'cross', className: 'button__icon' }),
    h('span.button__text', t('Schließen')));

    // Show iframe
    $openButton.addEventListener('click', () => {
      $openButton.remove();
      $chatbot.appendChild($closeButton);
      $chatbot.appendChild($iframe);
      $chatbot.classList.add(openClass);
    });

    // Remove iframe
    $closeButton.addEventListener('click', () => {
      $closeButton.remove();
      $iframe.remove();
      $chatbot.appendChild($openButton);
      $chatbot.classList.remove(openClass);
    });
  }
});
