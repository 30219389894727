import getTarget from 'javascripts/utils/get-target';

const onClick = (event) => {
  const $target = getTarget(event.target, '.js-toggle-accordion');

  if ($target) {
    // Stop event here
    event.preventDefault();

    // Get state
    const $item = $target.closest('.accordion__item');
    const $toggle = $item.querySelector('.js-toggle-accordion');
    const $content = document.getElementById($toggle.getAttribute('aria-controls'));

    // Toggle content
    const isOpen = $content.classList.toggle('accordion__content--visible');

    // Set aria state
    $toggle.setAttribute('aria-expanded', isOpen ? 'true' : 'false');

    // Focus on opening
    if (isOpen) {
      $content.focus();
    }
  }
};

const onHashchange = () => {
  // Has page a hash attached?
  if (window.location.hash) {
    // Find target
    const $target = document.getElementById(window.location.hash.substring(1));

    if ($target) {
      // Is target an accordion item?
      const $item = getTarget($target, '.accordion__item');

      if ($item) {
        // Open item
        const $toggle = $item.querySelector('.js-toggle-accordion');
        $toggle.click();
      }
    }
  }
};

document.addEventListener('click', onClick);
window.addEventListener('hashchange', onHashchange);
onHashchange();
