import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';

export default class DonationBox {
  blocked = false;

  constructor($donationBox) {
    this.$donationBox = $donationBox;

    // Add events
    this.$donationBox.addEventListener('change', this.onChange.bind(this));
    this.$donationBox.addEventListener('input', this.onInput.bind(this));

    // Add hidden field for URL param
    this.$hiddenAmountField = h('input', {
      type: 'hidden',
      name: 'amount',
      value: '',
    });

    // Place inside box
    this.$donationBox.appendChild(this.$hiddenAmountField);
  }

  onChange(event) {
    if (this.blocked) {
      return;
    }

    const $radio = getTarget(event.target, '.radio__input');

    // Trigger on radio buttons
    if ($radio) {
      // Block to prevent firing other events
      this.blocked = true;

      // Change input values
      this.$donationBox.querySelectorAll('.input').forEach(($el) => {
        $el.value = '';
      });

      // Set hidden field
      this.$hiddenAmountField.value = $radio.value;

      // Unblock
      this.blocked = false;
    }
  }

  onInput(event) {
    if (this.blocked) {
      return;
    }

    const $input = getTarget(event.target, '.input');

    // Trigger on radio buttons
    if ($input) {
      // Block to prevent firing other events
      this.blocked = true;

      // Change input values
      this.$donationBox.querySelectorAll('.radio__input').forEach(($el) => {
        $el.checked = false;
      });

      // Set hidden field
      this.$hiddenAmountField.value = $input.value;

      // Unblock
      this.blocked = false;
    }
  }
}

document.querySelectorAll('.donation-box').forEach($donationBox => new DonationBox($donationBox));
