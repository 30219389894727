import getTarget from 'javascripts/utils/get-target';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';
import { MEDIA_QUERIES } from 'javascripts/constants';

export default class OnPageNavigation {
  constructor($onPageNavigation) {
    this.$onPageNavigation = $onPageNavigation;
    this.$toggle = this.$onPageNavigation.querySelector('.on-page-navigation__toggle');
    this.$links = this.$onPageNavigation.querySelector('.on-page-navigation__links');
    this.links = this.$links.querySelectorAll('.link');

    // Init media query
    this.mql = window.matchMedia(MEDIA_QUERIES.l);
    this.mql.addListener(this.onResize.bind(this));
    this.onResize(this.mql);

    // Outside click
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);

    // Register events
    this.$toggle.addEventListener('click', this.onToggleClick.bind(this));
    this.$toggle.addEventListener('keydown', this.onToggleKeydown.bind(this));
    this.$links.addEventListener('click', this.onLinkClick.bind(this));
    this.$links.addEventListener('keydown', this.onLinkKeydown.bind(this));
  }

  openMenu() {
    // Set state
    this.$toggle.setAttribute('aria-expanded', 'true');
    this.$links.hidden = false;

    // Get first link and focus on it
    const $firstLink = this.links[0];
    unvisibleFocus($firstLink);

    // Catch outside clicks
    document.addEventListener('click', this.onOutsideClickBinded);
  }

  closeMenu() {
    // Set state
    this.$toggle.setAttribute('aria-expanded', 'false');
    this.$links.hidden = true;
  }

  toggleMenu() {
    const expanded = this.$toggle.getAttribute('aria-expanded') === 'true';
    return expanded ? this.closeMenu() : this.openMenu();
  }

  focusNext($currentLink, $startLink) {
    // Determine which item is the startItem (first or last)
    const down = $startLink === this.links[0];

    // Helper function for getting next legitimate element
    const move = ($el) => {
      const $nextLink = (down
        ? $el.parentNode.nextElementSibling : $el.parentNode.previousElementSibling);

      return ($nextLink && $nextLink.firstElementChild) || $startLink;
    };

    // Move and focus
    const $nextLink = move($currentLink);
    $nextLink.focus();
  }

  onResize(mediaQuery) {
    // Mobile/tablet menu
    if (!mediaQuery.matches) {
      this.$links.hidden = true;
    } else {
      this.closeMenu();
      this.$links.hidden = false;
    }
  }

  onOutsideClick(event) {
    if (!this.$links.contains(event.target) && !this.$toggle.contains(event.target)) {
      document.removeEventListener('click', this.onOutsideClickBinded);
      this.closeMenu();
    }
  }

  onToggleClick() {
    this.toggleMenu();
  }

  onToggleKeydown(event) {
    // Also toggle on down arrow
    if (event.keyCode === 40) {
      if (this.$links.hidden) {
        this.openMenu();
      } else {
        this.links[0].focus();
      }
    }

    // close menu on up arrow
    if (event.keyCode === 38) {
      this.closeMenu();
    }
  }

  onLinkClick(event) {
    const $link = getTarget(event.target, '.link');
    const isMenuCurrently = !this.mql.matches;

    // Close menu
    if ($link && isMenuCurrently) {
      this.closeMenu();
    }
  }

  onLinkKeydown(event) {
    const $link = getTarget(event.target, '.link');
    const isMenuCurrently = !this.mql.matches;

    if (!$link || !isMenuCurrently) {
      return;
    }

    // Down
    if (event.keyCode === 40) {
      event.preventDefault();
      this.focusNext($link, this.links[0]);
    }

    // Up
    if (event.keyCode === 38) {
      event.preventDefault();
      this.focusNext($link, this.links[this.links.length - 1]);
    }

    // Last tab
    if (event.keyCode === 9) {
      const $linkWrap = $link.closest('.on-page-navigation__link');
      const isLastChild = $linkWrap.matches(':last-child');

      if (isLastChild) {
        this.toggleMenu();
      }
    }

    // If escape, refocus menu button
    if (event.keyCode === 27) {
      event.preventDefault();
      this.toggleMenu();
      this.$toggle.focus();
    }
  }
}

document.querySelectorAll('.on-page-navigation').forEach($onPageNavigation => new OnPageNavigation($onPageNavigation));
