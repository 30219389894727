document.querySelectorAll('.list-map').forEach(async ($listMap) => {
  try {
    // Parse configuration
    const $configuration = $listMap.querySelector('.list-map__configuration');
    const configuration = JSON.parse($configuration.innerHTML);

    // Import list map
    const render = await import(/* webpackChunkName: "list-map" */ './ListMap');
    render($listMap, configuration);
  } catch {
    // Nothing
  }
});
