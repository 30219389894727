import h from 'hyperscript';
import { format } from 'date-fns';
import bookmarks from 'javascripts/globals/bookmarks';
import alert from 'components/molecules/alert/alert';
import { t } from 'javascripts/utils/withTranslation';
import icon from '../../_particles/icon/Icon';

export default class Bookmarks {
  constructor($bookmarks) {
    this.$bookmarks = $bookmarks;

    // Prepare table template
    this.$tableBody = h('tbody.table__body', this.renderBookmarks());
    this.$table = h(
      'table.table.bookmarks',
      h(
        'thead.table__head',
        h(
          'tr.table__row',
          h('th.table__header', { scope: 'col' }, t('Beitrag')),
          h('th.table__header', { scope: 'col' }, t('Gespeichert am')),
          h('th.table__header', { scope: 'col' }, t('Aktionen')),
        ),
      ),
      this.$tableBody,
    );

    // Render table
    this.$bookmarks.appendChild(this.$table);
  }

  renderBookmarks() {
    if (bookmarks.count() === 0) {
      return h(
        'tr.table__row',
        h(
          'td.table__data.bookmarks__no-bookmarks',
          { colSpan: 3 },
          t('Es wurden keine Seiten gemerkt.'),
        ),
      );
    }

    return bookmarks.all().map(bookmark => h(
      'tr.table__row',
      h('td.table__data.bookmarks__title', h('a.link', { href: bookmark.link }, bookmark.title)),
      h('td.table__data', format(bookmark.timestamp, 'DD.MM.YYYY')),
      h('td.table__data.bookmarks__actions', h(
        'button.icon-link',
        {
          type: 'button',
          onclick: () => this.deleteBookmark(bookmark),
        },
        icon({ icon: 'bin', className: 'icon-link__icon' }),
        h('span.icon-link__text', t('Löschen')),
      )),
    ));
  }

  reload() {
    // Delete table body
    while (this.$tableBody.firstChild) {
      this.$tableBody.removeChild(this.$tableBody.firstChild);
    }

    // Append new table body
    this.renderBookmarks().forEach($el => this.$tableBody.appendChild($el));
  }

  deleteBookmark(bookmark) {
    // Delete bookmark
    bookmarks.remove(bookmark);

    // Show alert
    alert({
      text: t('Die Seite wurde aus Merkliste gelöscht.'),
      button: t('Rückgängig'),
      timeout: 5000,
      callback: () => {
        bookmarks.add(bookmark);
        this.reload();
      },
    });

    // Reload table
    this.reload();
  }
}

document.querySelectorAll('.bookmarks').forEach($bookmarks => new Bookmarks($bookmarks));
