const environment = window.sdsh || {};

export const ASSET_BASE_URL = environment.ASSET_BASE_URL || '/';

export const ICON_SPRITE_URL = environment.ICON_SPRITE_URL || '/icons/icons.svg';

export const TITLE = environment.TITLE || 'Stiftung Deutsche Schlaganfall-Hilfe';

export const LANG = document.documentElement.lang || 'de';

export const MAPBOX_KEY = environment.MAPBOX_KEY || null;

export const PRIVACY_LINK = environment.PRIVACY_LINK || '';

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 37.5em)',
  l: '(min-width: 58.75em)',
  xl: '(min-width: 80em)',
};
