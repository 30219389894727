import h from 'hyperscript';
import icon from 'components/_particles/icon/Icon';
import { lory } from '@rsm/allfarblori';
import { t } from 'javascripts/utils/withTranslation';

export default class GallerySlider {
  constructor($gallerySlider) {
    this.$gallerySlider = $gallerySlider;
    this.$slides = this.$gallerySlider.querySelectorAll('.gallery-slider__slide');
    this.$inner = this.$gallerySlider.querySelector('.gallery-slider__inner');
    this.$slidesContainer = this.$gallerySlider.querySelector('.gallery-slider__slides-wrap');

    this.$gallerySlider.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$gallerySlider.addEventListener('after.lory.init', () => {
      this.$gallerySlider.classList.add('gallery-slider--initialized');
    });

    this.$gallerySlider.addEventListener('after.lory.slide', () => {
      this.updateCounter();
    });

    this.lory = lory(this.$gallerySlider, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'gallery-slider__slides-wrap',
      classNameSlideContainer: 'gallery-slider__slides',
      classNamePrevCtrl: 'gallery-slider__arrow--prev',
      classNameNextCtrl: 'gallery-slider__arrow--next',
      classNameDisabledPrevCtrl: 'gallery-slider__arrow--disabled',
      classNameDisabledNextCtrl: 'gallery-slider__arrow--disabled',
    });

    this.$slidesContainer.addEventListener('focusin', (event) => {
      this.$slidesContainer.scrollLeft = 0;
      const $slide = event.target.closest('.gallery-slider__slide');
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);
    });
  }

  initControls() {
    this.$counter = h('.gallery-slider__counter', `1 / ${this.$slides.length}`);

    this.$controlPrev = h(
      'button.gallery-slider__arrow.gallery-slider__arrow--prev',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Vorheriges Bild'),
      },
      icon({
        title: t('Vorheriges Bild'),
        icon: 'caret-left',
        viewBox: '0 0 120 200',
        className: 'gallery-slider__arrow-icon',
      }),
    );

    this.$controlNext = h(
      'button.gallery-slider__arrow.gallery-slider__arrow--next',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Nächstes Bild'),
      },
      icon({
        title: t('Nächstes Bild'),
        icon: 'caret-right',
        viewBox: '0 0 120 200',
        className: 'gallery-slider__arrow-icon',
      }),
    );

    this.$wrap = h('.gallery-slider__navigation', this.$counter, this.$controlPrev, this.$controlNext);

    this.$gallerySlider.insertBefore(this.$wrap, this.$inner);
  }

  updateCounter() {
    const currentSlide = this.lory.returnIndex() + 1;
    const totalSlides = this.$slides.length;

    this.$counter.innerHTML = `${currentSlide} / ${totalSlides}`;
  }
}

document.querySelectorAll('.gallery-slider').forEach($gallerySlider => new GallerySlider($gallerySlider));
