import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';
import { t } from 'javascripts/utils/withTranslation';

// Template for PhotoSwipe
const $photoswipeTemplate = h(
  '.pswp',
  {
    attrs: {
      tabindex: '-1',
      role: 'dialog',
      'aria-hidden': 'true',
    },
  },
  h('.pswp__bg'),
  h(
    '.pswp__scroll-wrap',
    h(
      '.pswp__container',
      h('.pswp__item'),
      h('.pswp__item'),
      h('.pswp__item'),
    ),
    h(
      '.pswp__ui.pswp__ui--hidden',
      h(
        '.pswp__top-bar',
        h('.pswp__counter'),
        h(
          'button.pswp__button.pswp__button--close',
          { title: t('Schließen') },
        ),
        h(
          'button.pswp__button.pswp__button--fs',
          { title: t('Vollbild') },
        ),
        h(
          'button.pswp__button.pswp__button--zoom',
          { title: t('Zoomen') },
        ),
        h(
          '.pswp__preloader',
          h(
            '.pswp__preloader__icn',
            h(
              '.pswp__preloader__cut',
              h('.pswp__preloader__donut'),
            ),
          ),
        ),
      ),
      h(
        'button.pswp__button.pswp__button--arrow--left',
        { title: t('Vorheriges Bild') },
      ),
      h(
        'button.pswp__button.pswp__button--arrow--right',
        { title: t('Nächstes Bild') },
      ),
      h(
        '.pswp__caption',
        h('.pswp__caption__center'),
      ),
    ),
  ),
);

// Open PhotoSwipe
const openPhotoSwipe = (images = [], index = 0) => {
  // Insert template
  const $template = $photoswipeTemplate.cloneNode(true);
  document.body.appendChild($template);

  // Init PhotoSwipe
  const slideshow = new PhotoSwipe(
    $template,
    PhotoSwipeUiDefault,
    images,
    {
      index,
      history: false,
      closeOnScroll: false,
      closeOnVerticalDrag: false,
      mouseUsed: true,
    },
  );

  // After gallery is closed, destory DOM
  slideshow.listen('destroy', () => $template.remove());

  // Start gallery
  slideshow.init();
};

const getCaption = ($image) => {
  if ($image.hasAttribute('data-caption')) {
    const $caption = document.getElementById($image.getAttribute('data-caption'));

    if ($caption) {
      return $caption.innerText;
    }
  }

  return null;
};

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-lightbox');

  if ($target) {
    event.preventDefault();

    const $gallery = $target.closest('.js-lightbox-gallery');

    if ($gallery) {
      const $images = $gallery.querySelectorAll('.js-lightbox');
      const images = [];

      $images.forEach(($image) => {
        images.push({
          src: $image.getAttribute('href'),
          w: $image.getAttribute('data-width'),
          h: $image.getAttribute('data-height'),
          title: getCaption($image),
        });
      });

      const index = Array.prototype.indexOf.call($images, $target);

      openPhotoSwipe(images, index);
    } else {
      openPhotoSwipe([{
        src: $target.getAttribute('href'),
        w: $target.getAttribute('data-width'),
        h: $target.getAttribute('data-height'),
        title: getCaption($target),
      }]);
    }
  }
});

export default openPhotoSwipe;
