import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $toggle = getTarget(event.target, '.sitemap__toggle');

  if ($toggle) {
    // Stop event here
    event.preventDefault();

    // Toggle aria state
    const isOpen = $toggle.getAttribute('aria-expanded') === 'true';
    $toggle.setAttribute('aria-expanded', !isOpen ? 'true' : 'false');

    // Focus list on opening
    if (!isOpen) {
      const $list = document.getElementById($toggle.getAttribute('aria-controls'));
      $list.setAttribute('tabindex', '0');
      $list.focus();
    }
  }
});
