import h from 'hyperscript';
import getVideoId from 'get-video-id';
import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $play = getTarget(event.target, '.figure__play');

  if ($play) {
    // Prevent default click event
    event.preventDefault();

    // Parse service and ID from video url
    const { id, service } = getVideoId($play.href);

    // Render video if YouTube
    if (id && service === 'youtube') {
      // Construct Youtube iframe player, add rel=0 to disable related videos
      const $iframe = h('.figure__iframe-wrapper', h('iframe.figure__iframe', {
        src: `https://www.youtube.com/embed/${id}?rel=0&autoplay=1`,
        allowFullscreen: true,
        msAllowFullscreen: true,
        webkitAllowFullscreen: true,
        mozAllowFullscreen: true,
      }));

      // Replace with iframe
      $play.parentNode.replaceChild($iframe, $play);
    }
  }
});
