import getTarget from 'javascripts/utils/get-target';

function switchStateForDropdown(isOpen, $dropdownContent, $dropdownControl) {
  $dropdownControl.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $dropdownContent.setAttribute('tabindex', isOpen ? '-1' : '0');
}

document.addEventListener('click', (event) => {
  const $dropdownControl = getTarget(event.target, '.list__filters-toggle');

  if ($dropdownControl) {
    const isOpen = $dropdownControl.getAttribute('aria-expanded') === 'true';
    const $dropdownContent = document.getElementById($dropdownControl.getAttribute('aria-controls'));

    // Switch state
    switchStateForDropdown(isOpen, $dropdownContent, $dropdownControl);

    // Remove tabindex on list after focusout
    const removeTabindexAfterFocusout = () => {
      $dropdownContent.removeAttribute('tabindex');
      $dropdownContent.removeEventListener('focusout', removeTabindexAfterFocusout);
    };

    // Focus list
    if (!isOpen) {
      $dropdownContent.removeAttribute('hidden');
      $dropdownContent.classList.add('list__filters-inner--visible');
      $dropdownContent.addEventListener('focusout', removeTabindexAfterFocusout);
      $dropdownContent.focus();
    } else {
      $dropdownContent.setAttribute('hidden', true);
      $dropdownContent.classList.remove('list__filters-inner--visible');
    }
  }
});
