import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { MAPBOX_KEY } from 'javascripts/constants';

// Mapbox token
mapboxgl.accessToken = MAPBOX_KEY;

// Generate map
document
  .querySelectorAll('.map')
  .forEach(($map) => {
    if (!$map.hasAttribute('data-location')) {
      return;
    }

    // Get location
    const location = $map.getAttribute('data-location')
      .split(',')
      .map(v => parseFloat(v.trim()));

    // Generate map
    const map = new mapboxgl.Map({
      container: $map,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: location,
      zoom: 16,
    });

    // Set language to German
    map.addControl(new MapboxLanguage({
      defaultLanguage: 'de',
    }));

    // Navigation
    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'bottom-left');

    // Create marker
    const marker = new mapboxgl.Marker({ color: '#df3320' })
      .setLngLat(location)
      .addTo(map);

    // Create popup
    if ($map.hasAttribute('data-label')) {
      const label = $map.getAttribute('data-label');
      const popup = new mapboxgl.Popup({ offset: 25 })
        .setHTML(`<div class="map__popup">${label}</div>`);

      marker.setPopup(popup);
    }
  });
