import h from 'hyperscript';
import { t } from 'javascripts/utils/withTranslation';

const supportsCSS = !!((window.CSS && window.CSS.supports) || window.supportsCSS || false);

const addAlertHeightToBody = ($alert) => {
  if (supportsCSS && CSS.supports('(position: sticky)')) {
    return () => {};
  }

  // Update padding bottom
  const updatePaddingBottom = (() => {
    const { offsetHeight } = $alert;
    document.body.style['padding-bottom'] = `${offsetHeight}px`;
  })();

  // Update body padding bottom on resize/orientationchange
  const updateOnResize = () => updatePaddingBottom();
  document.addEventListener('resize', updateOnResize);
  document.addEventListener('orientationchange', updateOnResize);

  // Reset function if alert is removeed
  return () => {
    document.removeEventListener('resize', updateOnResize);
    document.removeEventListener('orientationchange', updateOnResize);
    document.body.style['padding-bottom'] = '0px';
  };
};

export default function alert({
  text, button = t('OK'), callback = null, timeout = false,
}) {
  // Confirm button
  let $button = null;
  if (button) {
    $button = h('button.button.button--secondary.alert__button', {
      type: 'button',
    }, h('span.button__text', button));
  }

  // Generate alert template
  const $alert = h('.alert', h('.alert__text', text), $button);

  // Attach to body
  document.body.appendChild($alert);

  // Add padding to body
  const resetBody = addAlertHeightToBody($alert);

  // Button click handler
  $button.addEventListener('click', (event) => {
    $alert.remove();
    resetBody();

    if (callback) {
      callback.call(null, event);
    }
  });

  if (timeout) {
    let timeoutVar;

    // Remove alert after 'timeoutTime' seconds if timeout parameter is set
    const removeAlertTimeout = (timeoutTime) => {
      timeoutVar = setTimeout(() => {
        $alert.remove();
        resetBody();
      }, timeoutTime);
    };

    removeAlertTimeout(5000);

    // Clear timeout on ouseenter
    $alert.addEventListener('mouseenter', () => {
      clearTimeout(timeoutVar);
    });

    // Reinit removeAlertTimeout after mouseleave
    $alert.addEventListener('mouseleave', () => {
      removeAlertTimeout(2000);
    });
  }
}
