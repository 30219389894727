import store from 'store/dist/store.modern';
import alert from 'components/molecules/alert/alert';
import { t } from 'javascripts/utils/withTranslation';
import getTarget from '../utils/get-target';

// Types
export const TYPES = {
  1: 'page',
};

// Bookmark store
export class Bookmarks {
  constructor(storeInstance) {
    this.store = storeInstance;
    this.bookmarks = this.store.get('bookmarks') || [];
  }

  save() {
    this.store.set('bookmarks', this.bookmarks);
    this.store.set('bookmarksLastUpdate', Date.now());
  }

  key(id, type) {
    return `${type}:${id}`;
  }

  has({ id, type = 'page' }) {
    // Generate key
    const key = this.key(id, type);

    // Find bookmark by key
    return this.bookmarks.some(bookmark => bookmark[0] === key);
  }

  add({
    id, title, type = 'page', link, timestamp = Date.now(),
  }) {
    // Generate key
    const key = this.key(id, type);

    // Don't save again
    if (this.has({ id, type })) {
      return;
    }

    // Add item to store
    this.bookmarks.push([key, {
      id, title, link, type, timestamp,
    }]);

    // Save store
    this.save();
  }

  remove({ id, type = 'page' }) {
    // Generate key
    const key = this.key(id, type);

    // Remove item by key from store
    this.bookmarks = this.bookmarks.filter(bookmark => bookmark[0] !== key);

    // Save store
    this.save();
  }

  get({ id, type = 'page' }) {
    // Generate key
    const key = this.key(id, type);

    // Find bookmark by key
    return this.bookmarks
      .filter(bookmark => bookmark[0] === key)
      .map(bookmark => bookmark[1])
      .reduce(bookmark => bookmark, false);
  }

  all() {
    return this.bookmarks.map(bookmark => bookmark[1]);
  }

  allByType(type = 'page') {
    return this.all().filter(bookmark => bookmark.type === type);
  }

  count() {
    return this.all().length;
  }

  countByType(type) {
    return this.allByType(type).length;
  }
}

// Create bookmark store
const bookmarks = new Bookmarks(store);
export default bookmarks;

// Helper: Parse data-* from element
function getItemForElement($el) {
  // Get id and type
  try {
    const id = $el.getAttribute('data-id');
    const type = $el.getAttribute('data-type') || 'page';
    const link = $el.getAttribute('data-link') || window.location;
    const title = $el.getAttribute('data-title') || document.title;

    // Return item object
    return {
      id, type, link, title,
    };
  } catch (e) {
    return false;
  }
}

// Helper: Switch body class if bookmarks exists
function switchBodyClass() {
  if (bookmarks.count() > 0) {
    document.body.classList.add('has-bookmarks');
  } else {
    document.body.classList.remove('has-bookmarks');
  }
}

// Bookmark click event
document.addEventListener('click', (event) => {
  const $button = getTarget(event.target, '.js-bookmark');

  if ($button) {
    // Get item for element
    const item = getItemForElement($button);

    // Item not found
    if (item === false) {
      return;
    }

    // Save bookmark
    if (!bookmarks.has(item)) {
      // Add item to bookmark store
      bookmarks.add(item);

      // Add class
      $button.classList.add('u-is-bookmarked');

      // Show alert
      alert({
        text: t('Die Seite wurde in der Merkliste gespeichert.'),
        button: t('Rückgängig'),
        timeout: 5000,
        callback() {
          bookmarks.remove(item);
          $button.classList.remove('u-is-bookmarked');
        },
      });
    } else {
      // Remove item to bookmark store
      bookmarks.remove(item);

      // Remove class
      $button.classList.remove('u-is-bookmarked');

      // Show alert
      alert({
        text: t('Die Seite wurde aus Merkliste gelöscht.'),
        button: t('Rückgängig'),
        timeout: 5000,
        callback() {
          bookmarks.add(item);
          $button.classList.add('u-is-bookmarked');
        },
      });
    }

    // Update body class
    switchBodyClass();
  }
});

// Add .u-is-bookmarked to all bookmark buttons
document.querySelectorAll('.js-bookmark').forEach(($el) => {
  // Get item for element
  const item = getItemForElement($el);

  // Item not found
  if (item === false) {
    return;
  }

  // Has bookmark?
  if (bookmarks.has(item)) {
    $el.classList.add('u-is-bookmarked');
  }
});

// Add .has-bookmarks to body, if bookmarks exist
switchBodyClass();
